exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-services-api-integrations-and-automation-tsx": () => import("./../../../src/pages/services/api-integrations-and-automation.tsx" /* webpackChunkName: "component---src-pages-services-api-integrations-and-automation-tsx" */),
  "component---src-pages-services-cloud-solutions-tsx": () => import("./../../../src/pages/services/cloud-solutions.tsx" /* webpackChunkName: "component---src-pages-services-cloud-solutions-tsx" */),
  "component---src-pages-services-mobile-app-design-and-development-tsx": () => import("./../../../src/pages/services/mobile-app-design-and-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-design-and-development-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-web-design-and-development-tsx": () => import("./../../../src/pages/services/web-design-and-development.tsx" /* webpackChunkName: "component---src-pages-services-web-design-and-development-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

